@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@1,100&family=Petrona:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@1,100&family=Petrona:wght@100&family=Poppins:wght@200&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Libre+Franklin:ital,wght@1,100&family=Montserrat+Alternates:ital,wght@1,500&family=Petrona:wght@100&family=Poppins:wght@200&family=Raleway:wght@300&family=Roboto:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya:wght@600&display=swap');






html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


/* Begining Banner Section */
.banner {
  background-color: rgb(
    44,
    33,
    27
  ); /* For browsers that do not support gradients */
  background-image: linear-gradient(
    to right,
    rgb(44, 33, 27),
    rgb(143, 106, 66)
  );
  color: white;
  padding: 5px;
}
.banner-message {
  color: white;
  font-weight: 800;
  text-align: center;
}

/* End of Banner Component */

/* Start Nav Section */
.nav-bar {
  background-image: ivory;
  width: 100%;
  padding: 20px 0;
}

.nav-row {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  width: 100%;
}
a {
  color: black;
  font-size: 18px;
  /* font-family: 'Libre Franklin', sans-serif; */
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
}

a:hover {
  border-bottom: 2px solid rgb(219, 148, 87);
  transition: 0.3s;
}

.nav-items {
  padding-left: 10%;
  display: flex;
  width: 50%;
  justify-content: space-around;
  list-style: none;
}

.nav-h2 {
  font-family: "Libre Franklin", sans-serif;
  border: none;
  width: 450px;
  font-weight: 800;
  font-size: 26px;
  padding-left: 5%;
}
.nav-h2:hover{
  border: none;
}
.nav-href{
  font-family: "Libre Franklin", sans-serif;
  font-weight: 800;
}

.side-menu {
  display: none;
}

@media screen and (max-width: 1190px) {
  a {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 849px) {
  .nav-items {
    display: none;
  }

  .side-menu {
    display: block;
    padding-top: 5px;
    padding-right: 40px;
  }
  .hamburger-menu {
    margin-right: 3%;
    width: 35px; /* Width of the menu */
    height: 30px; /* Height of the menu */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .bar {
    width: 100%;
    height: 2px; /* Height of each bar */
    background-color: #333; /* Color of the bars */
    border-radius: 5px; /* Rounded corners */
  }
  
}

/* End of Nav Section */

/* Begining of the Hero Section */
.hero {
  background-image: url("https://www.amayatheme.redsun.design/roastery/wp-content/uploads/sites/2/2020/12/coffeebean-hero-espresso.jpg");
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  object-fit: cover;
  display: block;
  width: 100%;
  padding-top: 300px;
  padding-bottom: 200px;
}

.hero-content {
  margin-left: 5%;
  width: 90%;
}

.hero-h1 {
  letter-spacing: 1px;
  font-size: 3rem;
  font-weight: 600;
  width: 50%;
  font-family: "Alegreya", serif;
  padding-bottom: 20px;
}

.hero-h2 {
  color: rgb(219, 148, 87);
  font-size: 18px;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-family: sans-serif;
  letter-spacing: 1px;
  font-weight: 400;
}

.hero-line-break {
  border: 1px solid rgb(219, 148, 87);
  width: 10%;
}

.hero-p-tag {
  font-weight: 800px;
  font-size: 1.5rem;
  width: 40%;
  padding-top: 20px;
  color: rgb(58, 58, 58);
}

.page-h1 {
  font-size: 36px;
  color: 26px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media screen and (max-width: 1553px) {
  .hero {
    background-position: center;
    background-size: cover;
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .hero-h1 {
    font-size: 2rem;
    width: 50%;
  }

  .hero-p-tag {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 829px) {
  .hero-h2 {
    /* display: none; */
    width: 70%;
  }
}

@media screen and (max-width: 517px) {
  .hero {
    background-image: url("./Images/mobileImg.jpeg");
    background-position: left;
    background-size: cover;
    background-position: center;
    padding-top: 200px;
    padding-bottom: 200px;
  }

  .hero-content {
    margin-top: 400px;
  }

  .hero-h2 {
    padding-bottom: 10px;
    font-weight: 500;
  }
  .hero-h1 {
    font-size: 2rem;
    width: 100%;
  }
  .hero-line-break {
    width: 100%;
  }
  .hero-p-tag {
    width: 100%;
    font-size: 1rem;
  }
}

/*  Begining of Blog Component */

.blog-container {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
  padding-bottom: 90px;
  width: 80%;
  margin-left: 10%;
}

.blog-background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blog-card {
  width: 400px;
  padding-top: 300px;
  padding-bottom: 200px;
}

.blog-content {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.blog-tag {
  color: white;
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  padding-bottom: 10px;
  font-weight: 800;
  font-weight: bold;
}

.blog-h3 {
  color: white;
  font-family: "Alegreya", serif;
  font-size: 36px;
  padding-bottom: 10px;
}

.blog-btn {
  background-color: white;
  color: black;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.5px;
  width: 100px;
  border: 0px;
  padding: 10px;
  text-align: center;
}
.blog-btn:hover{
background-color: rgb(219, 148, 87);
color: white;
transition: all 500ms;
}

.side-menu {
  font-size: 26px;
}

@media screen and (max-width: 1553px) {
  .blog-card {
    width: 350px;
  }
}

@media screen and (max-width: 1370px) {
  .blog-container {
    display: flex;
    flex-direction: column;
    height: 2000px;
    width: 80%;
    margin-left: 10%;
  }

  .blog-background {
    display: flex;
    justify-content: space-around;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .blog-card {
    padding-bottom: 200px;
    width: 400px;
  }

  .blog-content {
    margin-left: 20%;
    display: flex !important;
    display: flex;

    flex-direction: column;
    justify-content: space-around;
    height: 140px;
  }

  .blog-tag {
    display: inline;
    color: white;
    letter-spacing: 1px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-weight: bold;
  }

  .blog-h3 {
    display: block;
    color: white;
    font-family: "Alegreya", serif;
    font-size: 36px;
  }

  .blog-p {
    display: inline;
    background-color: white;
    color: black;
    font-weight: 400;
    letter-spacing: 1.5px;
    width: 100px;
    text-align: center;
    padding: 8px;
  }
}

@media screen and (max-width: 514px) {
  .blog-tag {
    display: none;
  }
  .blog-h3 {
    font-size: 1.6rem;
  }
  .blog-p {
    padding: 7px;
    border-radius: 5px;
  }
  .blog-p:hover {
    background-color: rgb(219, 148, 87);
    color: white;
    transition: all 400ms;
  }
}
/*  End of Blog Component */

/* Begining of Footer */

.footer {
  background-color: #101020;
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer-content {
  display: flex;
  justify-content: space-around;
  align-content: center;
  width: 70%;
  margin-left: 15%;
}
.footer-coloum{
  display: flex;
  flex-direction: column;
  height:200px;
  justify-content: space-evenly;
}

.footer-h3 {
  color: white;
  font-size: 26px;
}

.footer-p {
  color: white;
  font-size: 18px;
}

.footer-p:hover {
  color: rgb(219, 148, 87);
  transition: all 400ms;
}

.svg-row {
  display: flex;
  justify-content: space-around;
  font-size: 46px;
}

.social-svg-insta {
  color: white;
}
.social-svg-x {
  color: white;
}
.social-svg-insta:hover {
  color: #c13584;
  transition: all 400ms;
}

.social-svg-x:hover {
  color: #1da1f2;
  transition: all 400ms;
}

@media screen and (max-width: 660px) {
  .footer-h3 {
    font-size: 18px;
  }

  .footer-p {
    font-size: 16px;
  }

  .svg-row {
    font-size: 26px;
  }
}

@media screen and (max-width: 660px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }
  .footer-h3 {
    font-size: 16px;
    font-weight: 800;
  }

  .footer-p {
    font-size: 16px;
  }

  .svg-row {
    font-size: 26px;
  }
}
/* End of footer Section */

.form-section {
  display: flex;
  padding-top: 0px;
  border-top: 1px solid lightgrey;
  width: 100%;
}
.form-left{
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('./Images/coffee-bar.webp');
  height: 800px;
  width: 100%;
}

.form {
  width: 100%;
  background-color: white;
  padding-top: 100px;
}

.form-header{
  text-align: center;
  padding: 20px;
}

.form-h1{
  width: 70%;
  margin-left: 15%;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 30px;
}

.form-p{
  width: 70%;
  font-family: "Poppins" ,sans-serif;
  font-weight: 600;
  margin-left: 15%;
  padding: 20px;
  font-size: 18px;
  line-height: 25px;
}

.form-label{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  text-transform: uppercase;
  margin-left: 15%;
}
.input {
  border: 1px solid lightgray;
  width: 70%;
  padding: 10px;
  margin-left: 15%;
}
.input:focus{
  border: 1px solid lightgray;
  outline: none;
}

.sign-up-btn{
  color: rgb(219, 148, 87);
  background-color:#101020;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: 1000;
  border: 0px;
  padding: 10px 20px;
  margin-left: 15%;
}

.sign-up-btn:hover{
  background-color: rgb(219, 148, 87);
  color: white;
}


.article-page{
  background-color: rgb(247,247,247);
  width: 100%;
  padding-bottom: 40px;
}

.article-div{
width: 70%;
padding-top: 40px;
margin-left: 15%;
}

.article-heading{
background-color: white;
text-align: center;
}

.article-tag{
  color: rgb(219, 148, 87);
  font-family: "Alegreya", serif;
  font-size: 28px;
  padding: 20px;
}

.article-h1{
  font-size: 36px;
  font-family: "Alegreya", serif;
}
.article-content{
  background-color: white;
  padding-top: 40px;
  padding-left: 40px;
}
.article-text{
  width: 70%;
  margin-left: 15%;
}

.article-date{
  font-size: 16px;
  padding: 20px;
  font-family: "Alegreya", serif;  
}

.article-img-div{
}

.article-img{
  width: 100%;
}

.article-h3{
  font-family: "Alegreya", serif;
  font-size: 26px;
}

.article-p{  
   padding: 20px;
   font-size: 18px;
   font-family: serif;
}

.article-ol{
  padding: 10px;
  list-style-type: number;
}

.article-li{
  font-family: "Alegreya", serif;
}

.article-socials{
  font-family: "Alegreya", serif;
  font-size: 18px;
  color: grey;
}

.article-socials-row{
  background-color: white;
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 50px;
  text-align: right;
  width: 100%;
}
.social-container{
  display: flex;
  align-items: center;
  width: 150px
}

.article-icon{
  padding: 10px;
  font-size: 22px;
  color: rgb(58, 58, 58);
}
.insta:hover{
  color: rgb(219, 148, 87);
  transition: all 400ms;
}
.x:hover{
  color: rgb(219, 148, 87);
  transition: all 400ms;
}

.recommended-article-div{
  width: 70%;
  margin-left: 15%;
  padding-top: 20px;
}

.recommended-div{
  background-color: white;
h4{
  color: #b9b9bc;
  text-align: center;
  font-family: system-ui;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1.8px;
  padding: 23px;
  text-transform: uppercase;
}
.article-flex{
  display: flex;
  justify-content: space-around;
}
}

.recomended-container{
  width: 30%;
  padding-bottom: 35px;
.recomended-img{
  width: 100%;
  align-self:center;
}
.img-text-container{
  padding: 10px;
}
.img-text-title{
  text-align: center;
  font-size: 20px;
  font-family: 'Alegreya', serif;
  padding: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}

.img-text-title:hover{
  color: rgb(219, 148, 87);
  transition: all 500ms;
}

.recomended-discription{
  width: 80%;
  text-align: center;
  margin-left: 10%;
  padding-top: 10px;
}
.img-text-date{
  text-align: center;
  font-family: 'Kalnia', serif;
  font-size: 18px;
  padding: 5px;
}
}

@media screen and (max-width: 962px) {
.article-div{
  width: 100%;
  margin-left: 0px;
}
.recommended-article-div{
  width: 100%;
  margin-left: 0px;
}
  .recommended-div{
  width: 100%;
}
}
@media screen and (max-width: 500px) {
  .article-flex{
    flex-direction: column;
  }
  .recomended-container{
    width: 80%;
    align-self: center;
  }
}